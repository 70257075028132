

    import {Component, Ref, Vue} from "vue-property-decorator";
    import RouteDialog from "../components/RouteDialog.vue";
    import {ChargePoint, IRouteDialog, RouteDialogConfirmEvent} from "@/domain";
    @Component({
        components: {RouteDialog}
    })
    export default class DeleteCentralSystemDialog extends Vue {
        @Ref('routeDialog')
        readonly routeDialogRef!: IRouteDialog;

        loading: boolean = true;

        async onConfirm(evt: RouteDialogConfirmEvent) {
            try {
                await this.$store.dispatch("centralSystems/deleteOne", this.$route.params.id);
                evt.done();
            } catch(e) {
                console.log("An error occured while deleting", e);
                evt.cancel();
            }
        }

        async mounted() {
            try {
                await this.$store.dispatch("centralSystems/fetchOne", this.$route.params.id);
                this.loading = false;
            } catch (e) {
                this.routeDialogRef.close();
            }
        }
    }
